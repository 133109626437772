import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {NavigationEnd, Router, RoutesRecognized} from '@angular/router';
import {filter} from 'rxjs/operators';
import {Location} from '@angular/common';
import {AppService} from './app.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {

    subscription: Subscription;

    constructor(private router: Router, private location: Location, private appService: AppService) {
    }

    ngOnInit() {
        this.subscription = this.router.events
            .pipe(
                filter(event => event instanceof NavigationEnd)
            )
            .subscribe(() => window.scrollTo(0, 0));

      this.router.events.subscribe(event => {
        if (event instanceof RoutesRecognized && event.url.includes('audit')) {
          const uuidRegex = /[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/g;
          const matches = event.url.match(uuidRegex);
          if (matches) {
            this.appService.setAuditByUUID(matches[0]);
          } else {
            this.router.navigate(['pages/error'])
          }
        }
      });
    }


    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }



}
