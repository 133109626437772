// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

export const environment = {
  production: false,
  backend: '/api', // Put your backend here
  appName: 'SAG Mockup',
  envName: 'DEV',
  test: false,
  i18nPrefix: '',
  autoDomainDiscovery: false,
//  auth: 'https://backoffice.ops.kiss.fr/auth',
//  api: 'https://backoffice.ops.kiss.fr/api',
};
