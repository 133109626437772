import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Audit} from './app.model';
import {BehaviorSubject} from 'rxjs';
import {Router} from '@angular/router';
import {environment} from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  private _audit = new BehaviorSubject<Audit>(null);
  public audit = this._audit.asObservable();

  constructor(private http: HttpClient, private router: Router) { }

  setAuditByUUID(UUID: string) {
    const lastAudit = this._audit.value;
    if (lastAudit === null || UUID !== lastAudit.id) {
      this.http.get(`${environment.backend}/auditsessions/${UUID}`).subscribe(
        (audit: any) => {
          if (audit) {
            this._audit.next(audit);
          }
        },
        (err) => {
          this.router.navigate(['pages/error']);
        });
    }
  }

  getAudit() {
    return this.audit;
  }
}
