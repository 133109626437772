import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-select-files',
  templateUrl: './select-files.component.html',
  styleUrls: ['./select-files.component.scss']
})
export class SelectFilesComponent implements OnInit {

  @Input()
  title: any;

  @Input()
  files: any[];

  @Output()
  selectedDisplay = new EventEmitter<any>();

  selectedFile: any;

  constructor() { }

  ngOnInit(): void {
    this.selectedFile = this.files[0];
  }

  changeSelectedDisplay(event) {
    this.selectedDisplay.emit(event);
  }

}
